const routes = {
    login: () => import('@/container/Login'),
    changePwd: () => import('@/container/ChangePwd'),
    test: () => import('@/container/Test'),

    // 面授课-面授课管理
    editOffline: (type) => import(/* webpackChunkName:"OfflineList" */'@/container/offline/offline-list/Index'),
    // 面授课-面授课视频上传
    offlineVideo: (classid) => import(/* webpackChunkName:"OfflineVideo" */'@/container/offline/offline-video/Index'),
    // 面授课-团购配置
    groupBuyingConf: () => import(/* webpackChunkName:"GroupConfiguration" */'@/container/offline/group-configuration/Index'),
    // 面授课-课号选择
    handleOffline: () => import(/* webpackChunkName:"HandleOffline" */'@/container/offline/offline-list/HandleOffline'),
    // 面授课-课程学员管理
    courseStudentManagement: () => import(/* webpackChunkName:"CourseStudentManagement" */'@/container/offline/course-student-management/Index'),

    // 营销管理-礼券模板
    couponTemplate: () => import(/* webpackChunkName:"CouponTemplate" */'@/container/marketing-manage/coupon-template/CouponTemplate'),
    // 营销管理-礼券发放
    couponIssuance: () => import(/* webpackChunkName:"CouponIssuance" */'@/container/marketing-manage/coupon-issuance/CouponIssuance'),
    // 营销管理-礼券统计
    couponStatistics: () => import(/* webpackChunkName:"CouponStatistics" */'@/container/marketing-manage/coupon-statistics/CouponStatistics'),
    // 营销管理-兑换码作废
    cancelCoupon: () => import(/* webpackChunkName:"CancelCoupon" */'@/container/marketing-manage/exchange-code/CancelCoupon'),
    // 营销管理-模版管理
    exchangeCodeModel: () => import(/* webpackChunkName:"ExchangeCodeModel" */'@/container/marketing-manage/exchange-code/ExchangeCodeModel'),
    // 营销管理-兑换码
    exchangeCode: () => import(/* webpackChunkName:"ExchangeCode" */'@/container/marketing-manage/exchange-code/ExchangeCode'),
    // 营销管理-生成兑换码
    generateExchangeCode: () => import(/* webpackChunkName:"GenerateExchangeCode" */'@/container/marketing-manage/exchange-code/GenerateExchangeCode'),
    // 营销管理-兑换直播课
    exchangeCouponNew: () => import(/* webpackChunkName:"ExchangeCouponNew" */'@/container/marketing-manage/exchange-code/ExchangeCouponNew'),
    // 营销管理-阶段管理
    sessionStageManage: () => import(/* webpackChunkName:"sessionStageManage" */'@/container/marketing-manage/session-stage-manage/Index'),
    // 营销管理-学习卡管理
    memberCard: () => import(/* webpackChunkName:"MemberCard" */'@/container/marketing-manage/member-card/Index'),
    // 营销管理-学习计划管理
    StudyPlanConfigure: () => import(/* webpackChunkName:"StudyPlanConfigure" */'@/container/marketing-manage/study-plan-configure/Index'),
    // 营销管理-联报优惠配置
    unionbuyOption: () => import(/* webpackChunkName:"UnionPreferentialConfiguration" */ '@/container/marketing-manage/union-preferential-configuration/Index'),
    // 营销管理-联报优惠配置-新增
    handleLianBao: () => import(/* webpackChunkName:"HandleLianBao" */'@/container/lian-bao-configure/HandleLianBao'),

    // 权限管理（管理员配置）
    configureAdmin: () => import(/* webpackChunkName:"AdministratorsConfigure" */'@/container/rights-management/administrators-configure/Index'),
    // 权限管理-添加角色
    'add-roles': () => import(/* webpackChunkName:"add-roles" */'@/container/rights-management/roles-manage/AddRoles'),
    // 权限管理-权限配置
    'permissions-config': () => import(/* webpackChunkName:"permissions-config" */'@/container/rights-management/permissions-config/Index'),

    // 直播课程-直播礼物配置
    liveGiftConf: () => import(/* webpackChunkName:"liveGiftConf" */'@/container/live-management/live-gift-management/Index'),
    // 直播课程-直播课退款（异常/过期）
    liveRefund: () => import('@/container/live-management/live-refund/LiveRefund'),
    // 直播课程-直播推流状态
    livePlugFlow: () => import(/* webpackChunkName:"livePlugFlow" */'@/container/live-management/live-plug-flow/LivePlugFlow'),
    // 直播课程-（虚拟直播）
    virtualLive: () => import(/* webpackChunkName:"VirtualLive" */'@/container/live-management/virtual-live/VirtualLive'),
    // 直播课程-图片生成
    generatePicture: () => import('@/container/live-management/generate-picture/Index'),
    // 直播课程-直播标签配置-新
    'live-label-config': () => import(/* webpackChunkName:"live-label-config" */'@/container/live-management/live-label-config/Index'),
    // 直播课程-虚拟配置
    configureLive: () => import(/* webpackChunkName:"ConfigureLive" */'@/container/live-management/ConfigureLive'),
    // 直播课程-直播大课配置-新增
    handleLive: () => import(/* webpackChunkName:"HandleLive.vue" */'@/container/live-management/HandleLive.vue'),
    // 直播课程-直播大课配置
    'big-course-config': () => import(/* webpackChunkName:"big-course-config" */'@/container/live-course/big-course-config/Index'),
    'self-study-room': () => import(/* webpackChunkName:"self-study-room" */'@/container/live-course/self-study-room/Index'),
    // 直播课程-直播大课配置-所有小课
    'all-subclass-course': () => import(/* webpackChunkName:"all-subclass-course" */'@/container/live-course/big-course-config/subclass-course/Index'),
    // 直播课程-直播大课配置-班主任
    'edit-header-master': () => import(/* webpackChunkName:"edit-header-master" */'@/container/live-course/big-course-config/edit-header-master/Index'),
    // 直播课程-直播课学习数据
    'live-study': () => import(/* webpackChunkName:"live-study" */'@/container/live-management/live-study/Index'),
    // 直播课程- 聊天记录
    'chat': () => import(/* webpackChunkName:"chat" */'@/container/live-management/chat-history/Index'),
    // 直播课程- 渠道预约配置
    'channel-reservation': () => import(/* webpackChunkName:"channel-reservation" */'@/container/live-course/channel-reservation/Index'),
    // 班级组-地点管理
    'places-manage': () => import(/* webpackChunkName:"places-manage" */'@/container/class-group-management/places-management/Index'),
    // 班级组-群组管理
    'group-manage': () => import(/* webpackChunkName:"group-manage" */'@/container/class-group-management/group-management/Index'),
    // 班级组-课号列表
    'class-number-manage': () => import(/* webpackChunkName:"class-number-manage" */'@/container/class-group-management/class-number-manage/Index'),
    // 班级组-教师管理-公开视频
    teacherGongkai: () => import(/* webpackChunkName:"teacherGongkai" */'@/container/TeacherGongkai'),
    // 班级组-教师管理-推荐课程
    teacherTuijian: () => import(/* webpackChunkName:"teacherTuijian" */'@/container/TeacherTuijian'),

    // 资料邮寄-快递单
    'express-bill': () => import(/* webpackChunkName:"express-bill" */'@/container/information-mailing/express-bill/ExpressBill'),
    // 资料邮寄-快递信息上传
    UploadExpressInfo: () => import(/* webpackChunkName:"UploadExpressInfo" */'@/container/information-mailing/upload-express-info/UploadExpressInfo'),
    // 资料邮寄-物品管理
    GoodsManagement: () => import(/* webpackChunkName:"GoodsManagement" */'@/container/information-mailing/goods-management/GoodsManagement'),
    // 资料邮寄-物品标签
    'goods-tag': () => import(/* webpackChunkName:"GoodsTag" */'@/container/information-mailing/goods-tag/TagMain'),
    // 资料邮寄-出库单
    'outbound-order': () => import(/* webpackChunkName:"outbound-order" */'@/container/information-mailing/outbound-order/Index'),

    // 用户管理-年卡会员管理
    yearCardMember: () => import(/* webpackChunkName:"yearCardManagement" */'@/container/user-manage/year-card-management/Index'),
    // 用户管理-消息通知
    messageNotification: () => import(/* webpackChunkName:"messageNotification" */'@/container/user-manage/message-notification/Index'),
    // 用户管理-聊天室黑名单
    ChatBlacklist: () => import(/* webpackChunkName:"ChatBlacklist" */'@/container/user-manage/chat-blacklist/Index'),
    // 用户管理-通知中心
    'notification-center': () => import(/* webpackChunkName:"exam-config" */'@/container/user-manage/notification-center/Index'),
    // 用户管理-激活码学生名单
    ActivationCode: () => import(/* webpackChunkName:"ActivationCode" */'@/container/user-manage/activation-code/Index'),
    // 用户管理-学习计划管理
    StudyPlan: () => import(/* webpackChunkName:"StudyPlan" */'@/container/user-manage/study-plan/Index'),
    // 用户管理-权限管理
    'adminRole': () => import(/* webpackChunkName:"adminRole" */'@/container/user-manage/admin-role/Index'),
    // 用户管理-审核角色
    'userRole': () => import(/* webpackChunkName:"userRole" */'@/container/user-manage/user-role/Index'),
    // 用户管理- 奖励配置
    'configAwards': () => import(/* webpackChunkName:"configAwards" */'@/container/user-manage/config-awards/Index'),
    // 用户管理- 编辑用户
    'userEdit': () => import(/* webpackChunkName:"userEdit" */'@/container/user-manage/user-edit/Index'),

    // 在线考试-考试题目组
    ExaminationQuestionsGroup: () => import(/* webpackChunkName:"ExaminationQuestionsGroup" */'@/container/online-exam/examination-questions-group/ExaminationQuestionsGroup'),
    // 在线考试-考试配置
    'exam-config': () => import(/* webpackChunkName:"exam-config" */'@/container/online-exam/exam-config/Index'),
    // 在线考试-重新判分
    'exam-regain-score': () => import(/* webpackChunkName:"exam-config" */'@/container/online-exam/reagain-score/Index'),
    // 在线考试-考试配置-阅卷/查卷-系统交卷
    examMarking: () => import(/* webpackChunkName:"examMarking" */'@/container/online-exam/exam-marking/Index'),
    // 在线考试-考试配置-阅卷/查卷-手动上传
    manualMarking: () => import(/* webpackChunkName:"manualMarking" */'@/container/online-exam/exam-marking/ManualMarking'),
    // 在线考试-考试配置-试卷页
    'exam-paper': () => import(/* webpackChunkName:"exam-paper" */'@/container/online-exam/exam-paper/Index'),

    // 宣传配置-知识点、刷题、弹窗广告配置
    configBanner: () => import(/* webpackChunkName:"configBanner" */'@/container/config-banner/Main'),
    // 宣传配置-APP搜索口令配置
    searchLive: () => import(/* webpackChunkName:"searchLive" */'@/container/promotion-config/search-live/Index'),
    // 宣传配置-app文章配置
    AppArticleConfigure: () => import(/* webpackChunkName:"AppArticleConfigure" */'@/container/promotion-config/app-article-configure/Index'),
    // 宣传配置-app文章类型配置
    AppArticleTypeConfigure: () => import(/* webpackChunkName:"AppArticleTypeConfigure" */'@/container/promotion-config/app-article-configure-type/Index'),
    // 宣传配置-推荐讲座配置
    RecommendedLecturesConfigure: () => import(/* webpackChunkName:"RecommendedLecturesConfigure" */'@/container/promotion-config/recommended-lectures-configure/Index'),
    // 宣传配置-app轮播图配置
    AppCarouselChartConfigure: () => import(/* webpackChunkName:"AppCarouselChartConfigure" */'@/container/promotion-config/app-carousel-chart-configure/Index'),
    // 宣传配置-页面渠道参数配置
    PageParametersConfigure: () => import(/* webpackChunkName:"PageParametersConfigure" */'@/container/promotion-config/page-parameters-configure/Index'),
    // 宣传配置-海报分享配置
    'poster-configure': () => import(/* webpackChunkName:"poster-configure" */'@/container/promotion-config/poster-configure/Index'),
    // 宣传配置-专题配置
    'special-config': () => import(/* webpackChunkName:"special-config" */'@/container/promotion-config/special-config/Index'),
    // 宣传配置-销售渠道参数配置
    'article-params-config': () => import(/* webpackChunkName:"article-params-config" */'@/container/promotion-config/article-params-config/Index'),
    // 宣传配置-欢迎页配置
    welcomeConfigure: () => import(/* webpackChunkName:"welcomeConfigure" */'@/container/welcome-config/Index'),

    // 订单管理-面授课退费
    refund: () => import(/* webpackChunkName:"ReFund" */'@/container/order-management/re-fund/ReFund.vue'),

    // 财务数据-直播课支付统计表
    livePayList: () => import('@/container/finance-management/live-pay-list/LivePayList'),
    // 财务数据-直播课退款统计表
    liveRefundList: () => import('@/container/finance-management/live-refund-list/LiveRefundList'),
    // 财务数据-直播课销课统计表
    liveSellingList: () => import('@/container/finance-management/live-selling-list/LiveSellingList'),
    // 财务数据-面授课支付统计表
    offlinePayList: () => import('@/container/finance-management/offline-pay-list/OfflinePayList'),
    // 财务数据-面授课退款统计表
    offlineRefundList: () => import('@/container/finance-management/offline-refund-list/OfflineRefundList'),

    // 图表统计-座位图*占座*订座表导出
    outputExcel: () => import(/* webpackChunkName:"SeatChartExport" */'@/container/chart-statistics/SeatChartExport'),

    // 系统设置-开放平台应用管理
    openPlatform: () => import('@/container/system-design/open-platform/OpenPlatform'),
    // 系统设置-支付主体配置
    Paymentchannelconfig: () => import(/* webpackChunkName:"HandleLive.vue" */'@/container/system-design/payment-config/Paymentchannelconfig.vue'),
    // 系统设置-操作日志
    logs: () => import('@/container/system-design/logs/Logs'),
    // 系统设置-系统限制
    system_limit: () => import('@/container/system-design/system-limit/SystemLimit'),
    // 系统设置-用户密钥
    UserKeyManagement: () => import(/* webpackChunkName:"UserKeyManagement" */'@/container/system-design/user-key-management/Index'),
    // 系统设置-公众号自动回复消息配置
    configureOfficialAccount: () => import('@/container/system-design/configure-official-account/ConfigureOfficialAccount'),
    // 系统设置-重置登录频繁限制
    'clear-login-frequent': () => import(/* webpackChunkName:"clear-login-frequent" */'@/container/system-design/clear-login-frequent/ClearLoginFrequent'),

    // 激励管理-质子获取模板
    'proton-get-template': () => import(/* webpackChunkName:"ProtonGetTemplate" */'@/container/incentive-management/proton-get-template/Index'),
    // 激励管理-质子获取模板-设置激励
    'proton-excitation-set': () => import(/* webpackChunkName:"ProtonExcitationSet" */'@/container/incentive-management/proton-get-template/SetExcitation'),
    // 激励管理-质子激励配置
    protonAwardConf: () => import(/* webpackChunkName:"ProtonAwardConf" */'@/container/incentive-management/proton-award-configuration/Index'),
    // 激励管理-质子商城
    ProtonShopping: () => import(/* webpackChunkName:"ProtonShopping" */'@/container/incentive-management/proton-shopping/ProtonShopping'),
    // 激励管理-批量赠送
    BatchGift: () => import(/* webpackChunkName:"BatchGift" */'@/container/incentive-management/batch-gift/BatchGift'),
    // 激励管理-赠送成就
    PresentAchievement: () => import(/* webpackChunkName:"PresentAchievement" */'@/container/incentive-management/present-achievement/Index'),
    // 激励管理-学号分配
    StudentNumberAssigned: () => import(/* webpackChunkName:"StudentNumberAssigned" */'@/container/incentive-management/student-number-assigned/Index'),

    // 学院直播-学校信息
    collegeSchool: () => import(/* webpackChunkName:"CollegeSchool" */'@/container/college-live/college-school/CollegeSchool'),
    // 学院直播-小课同步视频
    syncCourseVideo: () => import(/* webpackChunkName:"syncCourseVideo" */'@/container/college-live/sync-course-video/SyncCourseVideo'),
    // 学院直播-生成账号
    generateAccount: () => import(/* webpackChunkName:"generateAccount" */'@/container/college-live/generate-account/GenerateAccount'),
    // 学院直播-学习数据统计
    studyDataStatistic: () => import(/* webpackChunkName:"StudyDataStatistic" */'@/container/college-live/study-data-statistic/StudyDataStatistic'),

    quickBIReporting: (query) => import(/* webpackChunkName:"QuickBIReporting" */'@/container/quick-b-i-reporting/Index'),

    // 视频管理-视频管理
    videoManagement: () => import(/* webpackChunkName:"VideoManagement" */'@/container/video-management/VideoManagement'),

    // 投放活动-投放活动
    LaunchActivities: () => import(/* webpackChunkName:"LaunchActivities" */'@/container/launch-activities/Index'),
    // 投放活动-对话页配置
    AutoRecommnd: () => import(/* webpackChunkName:"AutoRecommnd" */'@/container/launch-activities/auto-recommnd/Index'),
    // 投放活动-落地页配置
    launchLandingPage: () => import(/* webpackChunkName:"launchLandingPage" */'@/container/launch-activities/LaunchLandingPage'),
    // 投放活动-登录页配置
    landingPageConfigure: () => import(/* webpackChunkName:"landingPageConfigure" */'@/container/launch-activities/LandingPageConfigure'),
    // 投放活动-pc登录页配置
    landingPagePcConfigure: () => import(/* webpackChunkName:"landingPagePcConfigure" */'@/container/launch-activities/LandingPagePcConfigure'),
    // 投放活动-咨询页配置
    appConsultingPage: () => import(/* webpackChunkName:"appConsultingPage" */'@/container/launch-activities/app/AppConsultingPage'),
    // 投放活动-app登录页配置
    appLandingPageConfigure: () => import(/* webpackChunkName:"appLandingPageConfigure" */'@/container/launch-activities/app/LandingPageConfigure'),
    // 投放活动-入口课专题页
    'entrance-course': () => import(/* webpackChunkName:"entrance-course" */'@/container/launch-activities/entrance-course/Index'),
    // 投放活动-知识页配置
    'knowledge-config': () => import(/* webpackChunkName:"knowledge-config" */'@/container/launch-activities/knowledge-config/Index'),
    // 投放活动管理->小程序配置
    'mini-program-config': () => import(/* webpackChunkName:"mini-program-config" */'@/container/launch-activities/mini-program-config/Index'),
    addEntranceCourseConfigure: () => import(/* webpackChunkName:"addEntranceCourseConfigure" */'@/container/launch-activities/entrance-course/addEntranceCourseConfigure'),
    // 投放活动-配置投放链接
    createPromotionlink: () => import(/* webpackChunkName:"AdministratorsConfigure" */'@/container/create-promotionlink/Index'),

    // 开罗尔官网配置-欢迎页配置
    chiralWelcomeConf: () => import(/* webpackChunkName:"chiralWelcomeConf" */'@/container/chiral-welcome-conf/Index'),
    // 开罗尔官网配置-推荐页配置
    chiralRecommendConf: () => import(/* webpackChunkName:"chiralRecommendConf" */'@/container/chiral-recommend-conf/Index'),

    // 平板管理-平板应用商店配置
    ShopConfig: () => import(/* webpackChunkName:"ShopConfig" */'@/container/pad-config/ShopConfig'),
    // 平板管理->定制课学生名单
    customizedClassStuList: () => import(/* webpackChunkName:"customizedClassStuList" */'@/container/customized-class-stu-list/Index'),

    // 定制课管理->助教作业管理
    taAssignmentManage: () => import(/* webpackChunkName:"TaAssignmentManage" */'@/container/customized-course/ta-assignment-management/TaAssignmentManage'),
    // 定制课管理-我的排课表
    mySchedule: () => import(/* webpackChunkName:"mySchedule" */'@/container/customized-course/my-schedule/Index'),

    // 故事应用-道具管理
    'item-manage': () => import(/* webpackChunkName:"ItemManage" */'@/container/story-app/ItemManage'),
    // 故事应用-关卡管理
    'level-manage': () => import(/* webpackChunkName:"level-manage" */'@/container/story-app/LevelManage'),
    // 故事应用-剧情管理
    'plot-manage': () => import(/* webpackChunkName:"PlotManage" */'@/container/plot-manage/Index'),

    // 文章管理-编辑文章
    'edit-article': () => import(/* webpackChunkName:"edit-article" */'@/container/article-manage/edit-article/Index'),

    // 用户反馈-用户反馈
    'user-feedback': () => import(/* webpackChunkName:"user-feedback" */'@/container/user-feedback/Index'),

    // 考试查询
    'exam-query': () => import(/* webpackChunkName:"exam-query" */'@/container/exam-query/Index'),

    // 质心营管理-学期信息
    'editSemester': () => import(/* webpackChunkName:"semester-information" */'@/container/zhixin-camp-management/semester-information/Index'),

    // 大月考配置-月考试卷管理
    'exam-paper-manage': () => import(/* webpackChunkName:"exam-paper-manage" */'@/container/monthly-exam/exam-paper-manage/Index'),
    // 大月考配置-助教管理
    'assistant-manage': () => import(/* webpackChunkName:"assistant-manage" */'@/container/monthly-exam/assistant-manage/Index'),
    // 大月考配置-招募管理
    'recruit-manage': () => import(/* webpackChunkName:"recruit-manage" */'@/container/monthly-exam/recruit-manage/Index'),
    // 大月考配置-助教批卷审核
    'assistant-examine': () => import(/* webpackChunkName:"assistant-examine" */'@/container/monthly-exam/assistant-examine/Index'),
    // 大月考配置-助教批卷管理
    'assistant-grading': () => import(/* webpackChunkName:"assistant-grading" */'@/container/monthly-exam/assistant-grading/Index'),
    // 大月考配置-题目打分反馈
    'question-scoring-feedback': () => import(/* webpackChunkName:"question-scoring-feedback" */'@/container/monthly-exam/question-scoring-feedback/Index'),
    // 大月考配置-教练报名邮寄
    'monthly-exam-mail': () => import(/* webpackChunkName:"monthly-exam-mail" */'@/container/monthly-exam/monthly-exam-mail/Index'),
    // 大月考配置-学生报名试卷管理
    'student-paper-manage': () => import(/* webpackChunkName:"student-paper-manage" */'@/container/monthly-exam/student-paper-manage/Index'),
    // 大月考配置-报名试卷管理
    'apply-paper-manage': () => import(/* webpackChunkName:"apply-paper-manage" */'@/container/monthly-exam/apply-paper-manage/Index'),
    // 大月考配置-运营广告配置
    'operational-advertising': () => import(/* webpackChunkName:"operational-advertising" */'@/container/monthly-exam/operational-advertising/Index'),

    // 资料上传
    'data-upload': () => import(/* webpackChunkName:"data-upload" */'@/container/data-upload/Index'),
    'data-type': () => import(/* webpackChunkName:"data-type" */'@/container/data-type/Index'),
    // 质子炼丹
    'environment-creation': () => import(/* webpackChunkName:"environment-creation" */'@/container/zhizi-liandan/EnvironmentCreation'),
    'composite-configuration': () => import(/* webpackChunkName:"composite-configuration" */'@/container/zhizi-liandan/CompositeConfiguration'),
};
export default routes;
